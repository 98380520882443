import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Inicio from "./components/Inicio";
import Header from "./components/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateRoute } from './components/PrivateRoute'
import Login from "./components/Login";

/*

id 98081565070-cmkl1959istf4cnu6dgqqkueermmvnjb.apps.googleusercontent.com
secret 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

*/

export default function App() {
  return (
    <Router>
      <Switch>
          <div className="container-fluid">
          <Header />
          <ToastContainer
          position="bottom-right"
          theme="colored"
          />
          <PrivateRoute exact path="/" component={Inicio} />
          <Route path="/login" component={Login} />
          </div>
        </Switch>
    </Router>
  );
}
