import React, { Component} from 'react'
import { userService } from '../../services/user.service'
import { Row, Col, Container, Form, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'
import Alert from 'react-bootstrap/Alert'
import { GoogleLogin } from 'react-google-login';





class Login extends Component {
    constructor(props){
        super(props)
        userService.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            tipovisualizacion: 'default',
            nombre: '',
            email: '',
            password_crear: '',
            ws_number: 'https://wa.me/56949636168?text=Hola%20tengo%20una%20consulta%20sobre%20DYM%20Solutions'
        }

        this.handleChange = this.handleChange.bind(this)
        this.responseGoogle = this.responseGoogle.bind(this)
    }

    responseGoogle(response){
    
        if(response.error) return toast.error('No se pudo iniciar sesión, intenta de nuevo')
    
        if(response.profileObj){
            if( typeof response.profileObj === 'object'){
    
                if(!response.profileObj.email) return toast.error('No se pudo iniciar sesión, intenta de nuevo')
    
                const object_user = {
                    status: "1",
                    email: response.profileObj.email,
                    nombre: response.profileObj.givenName,
                    apellido: response.profileObj.familyName,
                    login: "user"
                }
                localStorage.setItem('dym_user', JSON.stringify(object_user))
                this.props.history.push('/')
                
            }
        }
    
      }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    resetearPassword(){
        const { email } = this.state

        if(this.validateEmail(email) !== true)return toast.error('Email inválido')
        this.setState({ loading: true })
        return fetch(`https://aquicambias.com/server/dymsolutionsserver/public/api/reset?email=${email}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            toast.success('Te enviamos un email para reiniciar tu contraseña')
            return this.setState({ loading: false })
        })
        .catch(error => {
            toast.error('Error al realizar esta operación')
            return this.setState({ loading: false })
        })


    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    registrarme(){
        const { email, nombre, password_crear } = this.state

        if(!email || !nombre || !password_crear) return toast.error('Todos los campos son requeridos')

        if(this.validateEmail(email) !== true)return toast.error('Email inválido')

        this.setState({ loading: true })
        userService.register(nombre, email, password_crear )
            .then(
                user => {
                    if(user.status !== '200'){
                        this.setState({ errorLogin: user.message, loading: false })
                    } else {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    }
                    
                },
                error => this.setState({ error, loading: false })
            );


    }

    handleSubmit(){

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if (!(username && password )) {
            return alert("Sin datos")
        }

        this.setState({ loading: true });
        userService.login(username, password )
            .then(
                user => {
                    if(!user){
                        this.setState({ errorLogin: "Email o Contraseña erroneas", loading: false })
                    } else {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    }
                    
                },
                error => this.setState({ error, loading: false })
            );
    }

    showByTipoVisualizacion(){
        const { tipovisualizacion } = this.state

        if(tipovisualizacion==='default') return this.vistaLogin()
        if(tipovisualizacion==='invitado') return this.vistaInvitado()
        if(tipovisualizacion==='registrar') return this.registrarForm()
        if(tipovisualizacion==='contraseña') return this.olvidepassword()

    }


    checkEmail(email){
    
            this.setState({ loading: true })
            return fetch(`https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/webdym-xbzid/service/webdym/incoming_webhook/checkEmail?email=${email}`)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                this.setState({ loading: false })
                return res.status
            })
            .catch(error => {
                toast.error('Error al realizar esta operación')
                this.setState({ loading: false })
                return false
            })
    
    
        
    }


    async entrarInvitado(){
        const { nombre, email } = this.state

        if(!nombre || !email) return toast.error('Todos los campos son requeridos')

        const check = await this.checkEmail(email)

        if(check === "0") return toast.error('Este email está registrado, debes iniciar sesión o recuperar tu clave')

        if(this.validateEmail(email) !== true)return toast.error('Email inválido')
        
        localStorage.setItem('dym_user', JSON.stringify({ email, nombre, login: 'invitado' }));

        this.props.history.push('/');
    }

    olvidepassword(){
        const { email, errorLogin, loading } = this.state
        return <Row className="justify-content-md-center">
        <Col md={3} className="centerv">
     <h3>Olvidé mi contraseña</h3>

<Form.Group className="mb-3" >
<Form.Control type="email" name="email" placeholder="Email" value={email} onChange={this.handleChange}
/>
</Form.Group>

<Form.Group className="mb-3" >  
<div className="d-grid gap-2 mt-3">
{ loading ? <Spinner animation="border" /> : <Button onClick={() => this.resetearPassword() }  block variant="primary">REINICIAR CONTRASEÑA</Button> }  
{ errorLogin ? <p className="text-danger">{errorLogin}</p> : false }


<Alert.Link onClick={()=>this.setState({ tipovisualizacion: 'default' })}>Entrar con mi usuario</Alert.Link>

</div>
</Form.Group>


<Form.Text className="text-muted">
  
</Form.Text>
        </Col>
    </Row>

    }

    registrarForm(){
        const { nombre, loading,  errorLogin,  password_crear, email } = this.state
        return <Row className="justify-content-md-center">
        <Col md={3} className="centerv">
     <h3>Registrarme</h3>
        <Form.Group className="mb-3" >
<Form.Control name="nombre" placeholder="Nombre" value={nombre} onChange={this.handleChange}
/>
</Form.Group>

<Form.Group className="mb-3" >
<Form.Control type="email" name="email" placeholder="Email" value={email} onChange={this.handleChange}
/>
</Form.Group>

<Form.Group className="mb-3" >
<Form.Control type="password" name="password_crear" placeholder="Contraseña" value={password_crear} onChange={this.handleChange}
/>
</Form.Group>

<Form.Group className="mb-3" >  
<div className="d-grid gap-2 mt-3">
{ loading ? <Spinner animation="border" /> : <Button onClick={() => this.registrarme() }  block variant="success">REGISTRARME</Button> }  
{ errorLogin ? <p className="text-danger">{errorLogin}</p> : false }


<Alert.Link onClick={()=>this.setState({ tipovisualizacion: 'default' })}>Entrar con mi contraseña</Alert.Link>

</div>
</Form.Group>


<Form.Text className="text-muted">
  
</Form.Text>
        </Col>
    </Row>
    }

    vistaInvitado(){
        const { loading, email, nombre } = this.state
        return <Row className="justify-content-md-center">
        <Col md={3} className="centerv">
     <h3>Iniciar sesión</h3>
        <Form.Group className="mb-3" >
<Form.Control name="nombre" placeholder="Nombre" value={nombre} onChange={this.handleChange}
/>
</Form.Group>

<Form.Group className="mb-3" >
<Form.Control type="email" name="email" placeholder="Email" value={email} onChange={this.handleChange}
/>

</Form.Group>

<Form.Group className="mb-3" >  
<div className="d-grid gap-2 mt-3">
{ loading ? <Spinner animation="border" /> : <Button onClick={() => this.entrarInvitado() }  block variant="primary">ENTRAR COMO INVITADO</Button> }  


<Alert.Link onClick={()=>this.setState({ tipovisualizacion: 'default' })}>Entrar con mi contraseña</Alert.Link>

</div>
</Form.Group>


<Form.Text className="text-muted">
  
</Form.Text>
        </Col>
    </Row>
    }

    vistaLogin(){

        const { loading, errorLogin, username, password } = this.state
        return <Row className="justify-content-md-center">
        <Col md={3} className="centerv">
     <h3>Iniciar sesión o <Alert.Link onClick={()=>this.setState({ tipovisualizacion: 'registrar' })}>Registrarme</Alert.Link>
</h3>
        <Form.Group className="mb-3" >
<Form.Control type="email" name="username" placeholder="Email" value={username} onChange={this.handleChange}
/>
</Form.Group>

<Form.Group className="mb-3" >
<Form.Control type="password" name="password" placeholder="Contraseña" value={password} onChange={this.handleChange}
/>

</Form.Group>

<Form.Group className="mb-3" >  
<div className="d-grid gap-2 mt-3">
{ loading ? <Spinner animation="border" /> : <Button onClick={() => this.handleSubmit() }  block variant="primary">ENTRAR</Button> }  
        { errorLogin ? <p className="text-danger">{errorLogin}</p> : false }

        <GoogleLogin
    clientId="98081565070-cmkl1959istf4cnu6dgqqkueermmvnjb.apps.googleusercontent.com"
    buttonText="Iniciar sesión con google"
    onSuccess={this.responseGoogle}
    onFailure={this.responseGoogle}
    cookiePolicy={'single_host_origin'}
  />

        </div>
</Form.Group>

{ /* <Link to="/">Olvidé mi contraseña</Link> */ }

<Row>
    <Col xs={12}>
        <Alert.Link onClick={()=>this.setState({ tipovisualizacion: 'invitado' })}>Entrar como invitado</Alert.Link>
    </Col>
    <Col xs={12}>
        <Alert.Link style={{ color:'black' }} onClick={()=>this.setState({ tipovisualizacion: 'contraseña' })}>Olvidé mi contraseña</Alert.Link>
    </Col>
</Row>


<Form.Text className="text-muted">
  
</Form.Text>
        </Col>
    </Row>
    }

    showWs(href){

        return <div className="whatsapp">
            <a target="_blank" rel="noreferrer" href={href} >
                <img src="ws.png" alt="whatsapp" />
            </a>
        </div>
    }
    
    render(){
        const { ws_number } = this.state
        
        return(
            <Container>
                {this.showWs(ws_number)}
        {this.showByTipoVisualizacion()}

        
        
    </Container>
        )
    }
}


export default Login