import React from 'react'
import { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Header extends Component {
    constructor(){
        super()
        this.state = {
            user: JSON.parse( localStorage.getItem('dym_user') )
        }
    }

    


    render(){

        return <Row className="mb-3">
        <Col xs={6} >
        <img src="/logo.png" alt="logotipo" style={{ width: '100%', maxWidth: 200 }} />
        </Col>
        <Col xs={6}>
        </Col>
        </Row>
    }
}

export default Header